import React, { useState,useRef } from 'react';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { A, H1,Slogan } from '../components/styles'
import ContBlock from "../components/contBlock"
import Layout from "../components/layout"
import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"


export default function IndexPage({ pageContext: { node },location }) {
    const [submitLoading,setSubmitLoading] = useState(false)
    const contactSubmitFun = (e)=>{
        e.preventDefault()
        const name = e.target.querySelector("[name='name']").value || ''
        const email = e.target.querySelector("[name='email']").value || ''
        const address = e.target.querySelector("[name='address']").value || ''
        const mind = e.target.querySelector("[name='mind']").value || ''

        setSubmitLoading(true)
        fetch('http://47.97.32.112:1337/contact-forms',{
            method:'POST',
            headers:{
                'Content-Type':'application/json;charset=UTF-8'
            },
            mode:'cors',
            cache:'default',
            body: JSON.stringify({
                name,address,mind, email
            })
        })
        .then(res =>res.json())
        .then((data) => {
            setSubmitLoading(false)
            if(data.id){
                Toastify({
                    text: `你的问题或意见已经提交成功!`,
                    position:"center"
                }).showToast();
            }
        })
    }

    const subscribeSubmitFun = (e)=>{
        e.preventDefault()
        const name = e.target.querySelector("[name='name']").value || ''
        const email = e.target.querySelector("[name='email']").value || ''
        const company = e.target.querySelector("[name='company']").value || ''

        setSubmitLoading(true)
        fetch('http://47.97.32.112:1337/subscribe-forms',{
            method:'POST',
            headers:{
                'Content-Type':'application/json;charset=UTF-8'
            },
            mode:'cors',
            cache:'default',
            body: JSON.stringify({
                name,company,email
            })
        })
        .then(res =>res.json())
        .then((data) => {
            setSubmitLoading(false)
            if(data.id){
                Toastify({
                    text: `订阅注册成功!`,
                    position:"center"
                }).showToast();
            }
        })
    }

    let title= '',cont='',bool = node.link.url == "/contact"
    if(bool){
      title = "What's up?"
      cont = <form className="col-span-6" onSubmit={contactSubmitFun}>
      <input type="text" name="name" className="h-[50px] placeholder-black text-xl mb-10" required placeholder="Name *"/>
      <input type="text" name="email" className="h-[50px] placeholder-black text-xl mb-10" required placeholder="E-mail *"/>
      <input type="text" name="address" className="h-[50px] placeholder-black text-xl mb-10" required placeholder="Where are you located? *"/>
      <input type="text" name="mind" className="h-[50px] placeholder-black text-xl mb-10" required placeholder="Tell us what's on your mind *"/>
      <div className="mb-10 pt-4 flex items-start justify-between">
        <input className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-black checked:border-black focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" id="flexCheckDefault"/>
        <label className="form-check-label inline-block text-gray-800 w-[calc(100%-28px)]" for="flexCheckDefault">
        I’m okay with sharing my personal information with Gemfisher, and I’ve taken a look at your <a href="/privacy" className="underline">Privacy Policy</a> to see how you use it.
        </label>
      </div>
      <input type="submit" disabled={submitLoading} className="w-full h-[58px] bg-black border border-black text-white text-[28px] font-bold flex items-center justify-center transition-all hover:bg-white hover:text-black" value="Submit"/>
    </form>
    } else {
      title = "Sign up"
      cont = <form className="col-span-6" onSubmit={subscribeSubmitFun}>
        <input type="email" name="email" className="h-[50px] placeholder-black text-xl mb-10" required placeholder="E-mail address *"/>
        <input type="text" name="name" className="h-[50px] placeholder-black text-xl mb-10" required placeholder="Name *"/>
        <input type="text" name="company" className="h-[50px] placeholder-black text-xl mb-10" required placeholder="Company *"/>
        <input type="submit" disabled={submitLoading} className="w-full h-[58px] bg-black border border-black text-white text-[28px] font-bold flex items-center justify-center transition-all hover:bg-white hover:text-black" value="Submit"/>
        <p className="pt-14">We cherish the fact that you want to stay in touch with us. We will treat it as an invitation to your home, and we will never spam you with nonsense or share your data with anyone else. Read more about our <a href="/privacy" className="underline">Privacy policy here</a></p>
      </form>
    }

    return (
        <Layout>
            <GatsbySeo
            title={node.seo.metaTitle}
            description={node.seo.metaDescription}
            openGraph={{
                url: location.href,
                title: node.seo.metaTitle,
                description: node.seo.metaDescription,
                images: [
                  { url: node.seo.shareImage.url },
                ],
                site_name: 'gemfisher',
              }}
            />
            <div className="pb-[200px] lg:pb-[120px]">
               <h1 data-aos="fade-up" data-aos-duration="600" className="pb-[95px] pt-[260px] lg:pt-[165px] text-[200px] leading-none xl:text-[150px] lg:text-[calc(50px+10vw)]">{node.title.title}</h1>
               <figure data-aos="fade-down" data-aos-duration="600"><GatsbyImage className="w-full" image={getImage(node.seo.shareImage.localFile)} alt="banner"/></figure>
               <Slogan data-aos="fade-up" data-aos-offset="300" data-aos-duration="600" className="py-[200px] com-indent lg:py-[120px]">{node.title.description}</Slogan>
               {
                  bool?node.contact.map((n,i)=>{
                      return (
                        <div id={i} key={i} className={`flex items-start justify-between text-xl mb-[200px] md:mb-[120px] md:flex-col ${i%2!==0?'flex-row-reverse':''}`}>
                          <div className="w-[49%] space-x-2 md:w-full md:mb-10">
                              
                              <div className="mb-4 grid grid-cols-2 gap-2">
                                <p className="col-span-1" dangerouslySetInnerHTML={{__html: n.address}}/>
                                <p className="col-span-1"><a href={n.link} dangerouslySetInnerHTML={{__html: n.phone}}/></p>
                              </div>
                            
                              <A className="!ml-0" variant="link" href={n.link}>Get directions</A>
                          </div>
                          <figure data-aos="fade-up" data-aos-duration="600" className="w-[49%] md:w-full"><GatsbyImage className="w-full bg-light" image={getImage(n.image.localFile)} alt="banner"/></figure>
                        </div>
                      )
                    }):null
               }
               <ContBlock title={title} cont={cont} />
            </div>
        </Layout>
    )
}

